// This is resolve react and redux-toolkit warnings: https://github.com/reduxjs/redux-toolkit/issues/2008
import { ApolloProvider } from '@apollo/client';
import { useAuth0 } from '@auth0/auth0-react';
import { ErrorBoundary, setUser } from '@sentry/react';
import AppLoader from 'AppLoader';
import { Suspense, lazy, useEffect, useState } from 'react';
import 'symbol-observable';

import PageLoader from 'components/PageLoader';
import { showToast } from 'components/Toast';

import { useSwitchUserRole } from 'context/SwitchUserRole';
import { ThemeProvider } from 'context/Theme';

import { getUserRoleDisplayText } from 'enums/UserRole';

import useAuthApolloClient from 'hooks/useAuthApolloClient';
import usePostHog from 'hooks/usePostHog';
import useQueryParam from 'hooks/useQueryParam';
import useSentry from 'hooks/useSentry';
import useUpdateReloadPrompt from 'hooks/useUpdateReloadPrompt';

import ErrorPage from 'pages/ErrorPage';

import { useAppDispatch } from 'redux/store';
import {
  setUserAvailableRoles,
  setUserEmail,
  setUserId,
  setUserRole,
} from 'redux/user';

import styles from './App.module.scss';

const LazyApp = lazy(() => import('./LazyApp'));

function App(): JSX.Element | null {
  const [isPseudoLoading, setIsPseudoLoading] = useState<boolean>(true);

  const redirectTo = useQueryParam('redirectTo');
  const organizationCode = useQueryParam('organizationCode');
  const organizationName = useQueryParam('organizationName');

  const { client, resetClient } = useAuthApolloClient();

  const {
    registerResetCallback,
    isLoading: switchUserRoleIsLoading,
    error: switchUserRoleError,
    roleSwitchingTo,
  } = useSwitchUserRole();

  useEffect(() => {
    registerResetCallback(resetClient);
  }, [registerResetCallback, resetClient]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setIsPseudoLoading(false);
    }, 500);

    return () => clearTimeout(timeoutId);
  }, []);

  useUpdateReloadPrompt();

  const {
    user,
    error: auth0Error,
    isLoading,
    isAuthenticated,
    logout,
    loginWithRedirect,
    getAccessTokenSilently,
  } = useAuth0();

  useSentry();
  usePostHog();

  const dispatch = useAppDispatch();

  useEffect(() => {
    switchUserRoleError &&
      showToast(
        'roleSwitchError',
        'Error switching profiles',
        'There as an issue switching your profile. Please try again. If the problem persists, please contact support.',
        'error'
      );
  }, [switchUserRoleError]);

  useEffect(() => {
    if (auth0Error) {
      logout({
        logoutParams: {
          returnTo: window.location.origin,
        },
      });
    }
  }, [auth0Error, logout]);

  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      (async () => {
        const targetUrl = organizationCode
          ? organizationName
            ? `${window.location.pathname}?organizationCode=${organizationCode}&organizationName=${organizationName}`
            : `${window.location.pathname}?organizationCode=${organizationCode}`
          : window.location.pathname;

        await loginWithRedirect({
          authorizationParams:
            redirectTo === 'signup'
              ? {
                  action: 'signup',
                }
              : {},
          appState: {
            targetUrl,
          },
        });
      })();
    }
  }, [
    isLoading,
    isAuthenticated,
    redirectTo,
    organizationCode,
    organizationName,
    loginWithRedirect,
  ]);

  useEffect(() => {
    if (user?.sub && user?.email) {
      setUser({
        id: user.sub,
        email: user.email,
      });

      dispatch(setUserId(user.sub));
      dispatch(setUserEmail(user.email));

      const activeRole = user['https://app.syllabird.com/role/active'];
      const roles = user['https://app.syllabird.com/roles'];

      dispatch(setUserAvailableRoles(roles));

      if (activeRole || roles.length) {
        if (activeRole) {
          dispatch(setUserRole(activeRole));
          localStorage.setItem('syllabird.user.previousRole', activeRole);
        } else {
          dispatch(setUserRole(roles?.[0]));
          localStorage.setItem('syllabird.user.previousRole', roles?.[0] ?? '');
        }
      }
    }
  }, [user, getAccessTokenSilently, dispatch]);

  if (isLoading || !isAuthenticated) {
    return null;
  }

  const loadingIndicator = <PageLoader className={styles.LoadingIndicator} />;

  if (isPseudoLoading) {
    return loadingIndicator;
  }

  if (switchUserRoleIsLoading) {
    return (
      <PageLoader
        className={styles.LoadingIndicator}
        loadingMessage={`Switching ${
          roleSwitchingTo
            ? `to ${getUserRoleDisplayText(roleSwitchingTo)} profile`
            : 'profiles'
        }...`}
        headerMessage="Please Wait"
      />
    );
  }

  return (
    <ErrorBoundary fallback={<ErrorPage />}>
      <Suspense fallback={loadingIndicator}>
        <ApolloProvider client={client}>
          <ThemeProvider>
            <AppLoader>
              <LazyApp />
            </AppLoader>
          </ThemeProvider>
        </ApolloProvider>
      </Suspense>
    </ErrorBoundary>
  );
}

export default App;
