import { Auth0Provider } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';

import {
  auth0Audience,
  auth0ClientId,
  auth0Domain,
  auth0Scope,
} from 'auth/config';

interface Auth0ProviderWithHistoryProps {
  children: JSX.Element[] | JSX.Element;
}

const Auth0ProviderWithHistory = ({
  children,
}: Auth0ProviderWithHistoryProps) => {
  const navigate = useNavigate();

  const previousRole = localStorage.getItem('syllabird.user.previousRole');

  const onRedirectCallback = (appState: any) => {
    if (appState?.priceId) {
      navigate(`/account/upgrade?priceId=${appState?.priceId}`);
    } else {
      navigate(appState?.targetUrl ?? window.location.pathname);
    }
  };

  return (
    <Auth0Provider
      domain={auth0Domain ?? ''}
      clientId={auth0ClientId ?? ''}
      onRedirectCallback={onRedirectCallback}
      // We are setting this to true to restore Auth0 v1 refresh token flow
      // https://github.com/auth0/auth0-react/blob/main/MIGRATION_GUIDE.md#no-more-iframe-fallback-by-default-when-using-refresh-tokens
      useRefreshTokensFallback={true}
      useRefreshTokens={true}
      cacheLocation="localstorage"
      authorizationParams={{
        audience: auth0Audience,
        redirect_uri: window.location.origin,
        scope: auth0Scope,
        role: previousRole,
      }}
    >
      {children}
    </Auth0Provider>
  );
};

export default Auth0ProviderWithHistory;
