import classnames from 'classnames';
import { HTMLProps, ReactNode } from 'react';

import Logo from 'components/Logo';

import styles from './PageLoader.module.scss';

export interface PageLoaderProps
  extends Pick<HTMLProps<HTMLDivElement>, 'className'> {
  headerMessage?: ReactNode;
  loadingMessage?: ReactNode;
}

export default function PageLoader({
  className,
  headerMessage = 'Welcome to Syllabird!',
  loadingMessage = 'Loading your planner...',
}: PageLoaderProps) {
  return (
    <div className={classnames(className, styles.Container)}>
      <Logo className={styles.Logo} />
      <h1>{headerMessage}</h1>
      <h2>{loadingMessage}</h2>
      <div className={styles.BounceLoader} />
    </div>
  );
}
