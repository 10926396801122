import {
  PayloadAction,
  combineReducers,
  configureStore,
} from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';

import { calendarSlice } from './calendar';
import { coursesSlice } from './courses';
import { homeSlice } from './home';
import { homeFiltersLocalStorageMiddleware } from './middleware';
import { planningSlice } from './planning';
import { RootState } from './types';
import { userSlice } from './user';

export const resetStore = () => ({
  type: 'RESET_STATE',
});

const appReducer = combineReducers({
  home: homeSlice.reducer,
  calendar: calendarSlice.reducer,
  user: userSlice.reducer,
  planning: planningSlice.reducer,
  courses: coursesSlice.reducer,
});

const rootReducer = (
  state: RootState | undefined,
  action: PayloadAction<string>
) => {
  if (action.type === 'RESET_STATE') {
    state = undefined;
  }
  return appReducer(state, action);
};

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(homeFiltersLocalStorageMiddleware),
});

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = useDispatch.withTypes<AppDispatch>(); // Export a hook that can be reused to resolve types

export default store;
