enum UserRole {
  Teacher = 'teacher',
  Student = 'student',
  Parrot = 'parrot',
  OrganizationAdmin = 'organization_admin',
}

export function getUserRoleDisplayText(userRole: UserRole) {
  switch (userRole) {
    case UserRole.Teacher:
      return 'Teacher';
    case UserRole.Student:
      return 'Student';
    case UserRole.Parrot:
      return 'Parrot';
    case UserRole.OrganizationAdmin:
      return 'Organization Admin';
  }
}

export default UserRole;
