import { useAuth0 } from '@auth0/auth0-react';
import { ReactNode, createContext, useCallback, useRef, useState } from 'react';
import { useNavigate } from 'react-router';

import UserRole from 'enums/UserRole';

import { resetStore, useAppDispatch } from 'redux/store';

interface SwitchUserRoleContextType {
  switchUserRole: (role: UserRole) => Promise<void>;
  isLoading: boolean;
  error: Error | null;
  roleSwitchingTo: UserRole | null;
  registerResetCallback: (callback: () => Promise<void>) => void;
}

export const SwitchUserRoleContext = createContext<SwitchUserRoleContextType>({
  switchUserRole: async () => {},
  isLoading: false,
  error: null,
  roleSwitchingTo: null,
  registerResetCallback: () => {},
});

export function SwitchUserRoleProvider({ children }: { children: ReactNode }) {
  const { getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [roleSwitchingTo, setRoleSwitchingTo] = useState<UserRole | null>(null);
  const [error, setError] = useState<Error | null>(null);

  const resetCallbackRef = useRef<(() => Promise<void>) | null>(null);

  const registerResetCallback = useCallback((callback: () => Promise<void>) => {
    resetCallbackRef.current = callback;
  }, []);

  const switchUserRole = useCallback(
    async (role: UserRole, redirectPath?: string) => {
      setIsLoading(true);
      setError(null);
      setRoleSwitchingTo(role);

      try {
        await getAccessTokenSilently({
          authorizationParams: {
            role,
          },
          cacheMode: 'off',
        });

        localStorage.setItem('syllabird.user.previousRole', role);

        if (resetCallbackRef.current) {
          await resetCallbackRef.current();
        }

        dispatch(resetStore());

        navigate(redirectPath ?? '/');
      } catch (error) {
        setError(
          error instanceof Error ? error : new Error('Failed to switch role')
        );
      } finally {
        setIsLoading(false);
      }
    },
    [getAccessTokenSilently, dispatch, navigate]
  );

  const value = {
    switchUserRole,
    isLoading,
    error,
    roleSwitchingTo,
    registerResetCallback,
  };

  return (
    <SwitchUserRoleContext.Provider value={value}>
      {children}
    </SwitchUserRoleContext.Provider>
  );
}
